console.log('add-server loaded')

class Server {
    constructor(className) {
        if(Server.exists){
            return Server.instance
        }
        this.adContainers = document.getElementsByClassName(className);
        this.regenerate()
        Server.exists = true
        Server.instance = this;
        return this
    }

    url_generator = (div_container) => {
        // const domain = 'http://localhost:3010/'
        const domain = 'https://app.konsylium24.pl'
        const user = div_container.getAttribute('data-user')
        const place = div_container.getAttribute('data-place')
        const billboard = div_container.getAttribute('data-billboard')
        const slot = div_container.getAttribute('data-slot')
        const cookie = div_container.getAttribute('data-cookie')
        const session = div_container.getAttribute('data-session')
        const section = 2 // id of test section
        return [`${domain}/toolkit_new/${user}?session=${session}&miejsce=${place}&slot=${slot}&billboard=${billboard}&k24_val_uu=${cookie}&grupa=brak&ad_section=${section}&k24_main_page=0`,
            div_container]
    }

    display = (html, container) => {
        container.innerHTML = html;
        container.style.minHeight = `auto`;
        const from_server_container = container.querySelector('.toolkit_baner_server')
        if (from_server_container) {
            from_server_container.classList.add('text-center');
        }
    }

    request = (url_container) => {
        const [url, container] = url_container
        const mobile = container.getAttribute('data-mobile')
        const widthOutput = window.innerWidth;
        if (widthOutput > 800 && mobile === '1') {
            return "";
        }
        if (widthOutput <= 800 && mobile !== '1') {
            return "";
        }
        if (mobile === "0") {
            container.style.minHeight = `${220}px`
        }
        const xhr = new XMLHttpRequest();
        const place = container.getAttribute('data-place')
        xhr.open('GET', url);
        xhr.send(null);
        xhr.onreadystatechange = () => {
            const DONE = 4;
            const OK = 200;
            if (xhr.readyState === DONE) {
                if (xhr.status === OK) {
                    const html = `<div id="show_my_ad_${place}">${xhr.responseText}</div>`
                    this.display(html, container)
                    let arr = container.getElementsByTagName('script')
                    for (let n = 0; n < arr.length; n++) {
                        try {
                            console.log('reklama');
                            eval(arr[n].innerHTML)

                        } catch (error) {
                            console.log('errr');
                            console.log(error);
                        }

                    }
                } else {
                    console.log('Error: ' + xhr.status);
                }
            }
        }
    }
    generate = () => {
        console.log(this.adContainers.length)
        for (let i = 0; i < this.adContainers.length; i++) {
            this.adContainers[i].innerHTML = "";
            const url_container = this.url_generator(this.adContainers[i])
            this.request(url_container)
        }

    }
    unload = () => {
        for (let i = 0; i < this.adContainers.length; i++) {
            this.adContainers[i].innerHTML = "";
        }
    }
    regenerate = () => {
        (() => {
            const event = new Event('resize-end');
            let resizeEnd;
            window.addEventListener('resize', () => {
                clearTimeout(resizeEnd);
                resizeEnd = setTimeout(() => {
                    window.dispatchEvent(event)
                }, 2500);
            });
        })()
        // window.addEventListener('resize-end', this.generate_ads, false);
    }
}

document.addEventListener('turbolinks:before-render', () => {
    const server = new Server('server-container')
    server.unload()
});

document.addEventListener("turbolinks:load", function () {
    const server = new Server('server-container')
    server.generate()
}, {
    once: true,
})
document.addEventListener("turbolinks:render", function () {
    const server = new Server('server-container')
    server.generate()
}, )
document.addEventListener("DOMContentLoaded", function(event) {
        const server = new Server('server-container')
        server.generate()
}
)
